<template>
    <div>
      <div class="row">
        <div class="col-md-12">
          <KTPortlet v-bind:title="title">
            <template v-slot:body>
              <b-alert
                :show="form.errors && form.errors.length > 0"
                variant="light"
                class="alert red lighten-4"
                ref="alert"
              >
                <div class="alert-icon">
                  <i class="flaticon-warning kt-font-danger"></i>
                </div>
                <div class="alert-text">
                  <div v-for="err in form.errors" :key="err">
                    {{ err }}
                  </div>
                </div>
              </b-alert>
              <v-form ref="form" @submit.prevent="submitForm">
                <div class="row">
                  <div class="col-6">
                    <v-text-field
                      ref="searchKeyword"
                      v-model="form.searchKeyword"
                      :disabled="datatable.loading"
                      :label="$t('SHARED.KEYWORD')"
                      :hint="$t('REPORT.RANK_BELL_POINT.KEYWORD_HINT')"
                      prepend-icon="mdi-file-document-box-search-outline"
                    ></v-text-field>
                  </div>

                  <div class="col-3">
                    <v-autocomplete
                      v-model="form.startMemberCard"
                      :disabled="datatable.loading"
                      :items="form.startMemberCardItems"
                      :loading="form.startMemberCardLoading"
                      :search-input.sync="form.startMemberCardSearch"
                      @change="ChangeStartMemberCard"
                      hide-no-data
                      hide-selected
                      item-text="text"
                      item-value="value"
                      :label="$t('REPORT.RANK_BELL_POINT.START_MEMBER_CARD')"
                      :placeholder="
                        $t('SHARED.START_TYPING_TO_SEARCH')
                      "
                      prepend-icon="mdi-database-search"
                      return-object
                      clearable
                    ></v-autocomplete>
                  </div>
  
                  <div class="col-3">
                    <v-autocomplete
                      v-model="form.endMemberCard"
                      :disabled="datatable.loading"
                      :items="form.endMemberCardItems"
                      :loading="form.endMemberCardLoading"
                      :search-input.sync="form.endMemberCardSearch"
                      hide-no-data
                      hide-selected
                      item-text="text"
                      item-value="value"
                      :label="$t('REPORT.RANK_BELL_POINT.END_MEMBER_CARD')"
                      :placeholder="
                        $t('SHARED.START_TYPING_TO_SEARCH')
                      "
                      prepend-icon="mdi-database-search"
                      return-object
                      clearable
                    ></v-autocomplete>
                  </div>
  
                  <div class="col-3">
                    <v-autocomplete
                      v-model="form.memberClassCode"
                      :disabled="datatable.loading"
                      :items="form.memberClassCodeItems"
                      hide-no-data
                      hide-selected
                      item-text="text"
                      item-value="value"
                      :label="$t('REPORT.CUSTOMER_DETAIL.MEMBER_CLASS_CODE')"
                      :placeholder="
                        $t('SHARED.START_TYPING_TO_SEARCH')"
                      prepend-icon="mdi-database-search"
                      return-object
                      clearable
                    ></v-autocomplete>
                  </div>

                  <div class="col-3">
                    <v-autocomplete
                      v-model="form.typePoint"
                      :disabled="datatable.loading"
                      :items="form.typePointItems"
                      hide-no-data
                      hide-selected
                      item-text="text"
                      item-value="value"
                      :label="$t('REPORT.RANK_BELL_POINT.TYPE_POINT')"
                      :placeholder="
                        $t('SHARED.START_TYPING_TO_SEARCH')"
                      prepend-icon="mdi-database-search"
                      return-object
                      clearable
                    ></v-autocomplete>
                  </div>

                  <div class="col-3">
                    <v-text-field
                      ref="startPoint"
                      v-model="form.startPoint"
                      :disabled="datatable.loading"
                      :label="$t('REPORT.RANK_BELL_POINT.START_POINT')"
                      :hint="$t('REPORT.RANK_BELL_POINT.START_POINT_HINT')"
                      prepend-icon="mdi-file-document-box-search-outline"
                      type="number"
                    ></v-text-field>
                  </div>

                  <div class="col-3">
                    <v-text-field
                      ref="endPoint"
                      v-model="form.endPoint"
                      :disabled="datatable.loading"
                      :label="$t('REPORT.RANK_BELL_POINT.END_POINT')"
                      :hint="$t('REPORT.RANK_BELL_POINT.END_POINT_HINT')"
                      prepend-icon="mdi-file-document-box-search-outline"
                      type="number"
                    ></v-text-field>
                  </div>

                  <div class="col-3">
                    <v-text-field
                      ref="rankPoint"
                      v-model="form.rankPoint"
                      :disabled="datatable.loading"
                      :label="$t('REPORT.RANK_BELL_POINT.RANK_POINT')"
                      :hint="$t('REPORT.RANK_BELL_POINT.RANK_POINT_HINT')"
                      prepend-icon="mdi-file-document-box-search-outline"
                      type="number"
                    ></v-text-field>
                  </div>

                </div>
                <div class="row">
                  <div class="col-12">
                    <v-btn
                      :disabled="datatable.loading"
                      color="success"
                      class="mr-4"
                      tile
                      type="submit"
                    >
                      <v-icon v-if="!datatable.loading" left
                        >mdi-database-search</v-icon
                      >
                      <v-icon v-if="datatable.loading" left
                        >fa fa-spinner fa-spin</v-icon
                      >
                      {{ $t("SHARED.SEARCH_BUTTON") }}
                    </v-btn>
                    <v-btn
                      :disabled="datatable.loading"
                      color="default"
                      class="mr-4"
                      type="reset"
                      tile
                      @click.prevent="resetForm"
                    >
                      <v-icon left>mdi-eraser</v-icon>
                      {{ $t("SHARED.RESET_BUTTON") }}
                    </v-btn>
                    <v-btn
                      :disabled="datatable.loading"
                      color="warning"
                      class="mr-4"
                      tile
                      @click.prevent="Export"
                    >
                      <v-icon v-if="!datatable.loading" left
                        >fas fa-file-export</v-icon
                      >
                      <v-icon v-if="datatable.loading" left
                        >fa fa-spinner fa-spin</v-icon
                      >
                      {{ $t("SHARED.EXPORT_BUTTON") }}
                    </v-btn>
                  </div>
                </div>
              </v-form>
  
              <v-divider class="my-4"></v-divider>
  
              <v-subheader>
                <v-icon left>mdi-table-search</v-icon>
                {{ $t("SHARED.SEARCH_RESULT") }}
                <v-spacer></v-spacer>
              </v-subheader>
  
              <v-data-table
                :headers="datatable.headers"
                :items="datatable.items"
                :loading="datatable.loading"
                :options.sync="datatable.options"
                :server-items-length="datatable.total"
                :footer-props="{
                  'items-per-page-options': [30, 50, 100],
                }"
                multi-sort
              ></v-data-table>
  
              <v-dialog v-model="datatable.loading" persistent width="300">
                <v-card>
                  <v-card-title class="headline">{{
                    $t("SHARED.PLEASE_WAIT")
                  }}</v-card-title>
                  <v-card-text>
                    <p>{{ $t("SHARED.PROCESSING") }}</p>
                    <v-progress-linear
                      indeterminate
                      color="amber lighten-3"
                      class="mb-3"
                    ></v-progress-linear>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </template>
          </KTPortlet>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import ApiService from "@/common/api.service";
  import JwtService from "@/common/jwt.service";
  import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
  import KTPortlet from "@/views/partials/content/Portlet.vue";
  
  export default {
    components: {
      KTPortlet,
    },
    data() {
      return {
        form: {
          searchKeyword: "",
          valid: true,
          dialog: false,
          loading: false,
          errors: [],

          name: "",
          memberClassCode: null,
          memberClassCodeItems: [
            {
              text: this.$t("SYS_VARIABLE.MEMBER_CLASS_NORMAL"),
              value: "MEMBER_CLASS_NORMAL",
            },
            {
              text: this.$t("SYS_VARIABLE.MEMBER_CLASS_GOLD"),
              value: "MEMBER_CLASS_GOLD",
            },
          ],

          startPoint: null,
          endPoint: null,
          rankPoint: null,

          typePoint: null,
          typePointItems: [
            {
              text: this.$t("REPORT.RANK_BELL_POINT.SUM_POINT"),
              value: "SUM_POINT",
            },
            {
              text: this.$t("REPORT.RANK_BELL_POINT.BALANCE_POINT"),
              value: "BALANCE_POINT",
            },
          ],

          startMemberCard: null,
          startMemberCardLoading: false,
          startMemberCardItems: [],
          startMemberCardSearch: "",
          
          endMemberCard: null,
          endMemberCardLoading: false,
          endMemberCardItems: [],
          endMemberCardSearch: "",
        },
        datatable: {headers: [
            {
              text: this.$t("REPORT.RANK_BELL_POINT.CARD_NUMBER"),
              value: "cardNumber",
              align: "center",
            },
            {
              text: this.$t("REPORT.RANK_BELL_POINT.MEMBER_CLASS_CODE"),
              value: "memberClassName",
              align: "center",
            },
            {
              text: this.$t("REPORT.RANK_BELL_POINT.FIRST_NAME"),
              value: "firstName",
              align: "center",
            },
            {
              text: this.$t("REPORT.RANK_BELL_POINT.LAST_NAME"),
              value: "lastName",
              align: "center",
            },
            {
              text: this.$t("REPORT.RANK_BELL_POINT.BRANCH_CODE"),
              value: "branchCode",
              align: "center",
            },
            {
              text: this.$t("REPORT.RANK_BELL_POINT.BRANCH_NAMETH"),
              value: "nameTH",
            },
            {
              text: this.$t("REPORT.RANK_BELL_POINT.SUM_POINT"),
              value: "sumPoint",
            },
            {
              text: this.$t("REPORT.RANK_BELL_POINT.BALANCE_POINT"),
              value: "balancePoint",
            },
            {
              text: this.$t("REPORT.RANK_BELL_POINT.REGISTERED_DATE"),
              value: "registeredDateString",
            },
            {
              text: this.$t("REPORT.RANK_BELL_POINT.EXPIRE_DATE"),
              value: "expireDateString",
            }
          ],
          items: [],
          total: 0,
          loading: true,
          options: {
            sortBy: ["balancePoint"],
            sortDesc: [true],
            itemsPerPage: 30,
          },
        },
      };
    },
    methods: {
      submitForm() {
        this.form.errors = [];
        // CHECK INPUT
        var checkInputRankPoint = [];
        checkInputRankPoint.push(this.form.typePoint != null ? true : false);
        checkInputRankPoint.push(Boolean(this.form.startPoint));
        checkInputRankPoint.push(Boolean(this.form.endPoint));
        checkInputRankPoint.push(Boolean(this.form.rankPoint));

        var countInput = checkInputRankPoint.filter((val) => { return val == true;})
        if(countInput.length > 1 && countInput.length < 4){
          if(this.form.typePoint == null){
            this.form.errors.push(
              "กรุณาเลือกประเภทพ้อยท์"
            );
          }

          if(Boolean(this.form.startPoint) == false){
            this.form.errors.push(
              "กรุณากรอกพ้อยท์ ตั้งแต่"
            );
          }

          if(Boolean(this.form.endPoint) == false){
            this.form.errors.push(
              "กรุณากรอกพ้อยท์ ถึง"
            );
          }

          if(Boolean(this.form.rankPoint) == false){
            this.form.errors.push(
              "กรุณากรอกลำดับ(Rank)"
            );
          }
        }else if(countInput.length == 4){
          if(this.form.startPoint < 0){
            this.form.errors.push(
              "กรุณากรอกพ้อยท์ ตั้งแต่เริ่มต้นจาก 0"
            );
          }

          if(parseInt(this.form.endPoint) < parseInt(this.form.startPoint) || parseInt(this.form.endPoint) == parseInt(this.form.startPoint)){
            this.form.errors.push(
              "กรุณากรอกพ้อยท์ ตั้งแต่ ให้ไม่เท่ากันหรือน้อยกว่า พ้อยท์ ถึง"
            );
          }

          if(this.form.end < 0){
            this.form.errors.push(
              "กรุณากรอกพ้อยท์ ถึงเริ่มต้นจาก 0"
            );
          }

          if(this.form.rankPoint < 1){
            this.form.errors.push(
              "กรุณาลำดับ(Rank) เริ่มต้นจาก 1"
            );
          }
        }
        // CHECK INPUT
        if (this.form.errors.length == 0) {
          this.getDataFromApi().then((data) => {
            this.datatable.total = data.total;
            this.datatable.items = data.items;
          });
        }
      },
      resetForm() {
        this.form.errors = [];
        this.form.searchKeyword = "";
        this.form.memberClassCode = null;
        this.form.startMemberCard = null;
        this.form.endMemberCard = null;
        this.form.typePoint = null;
        this.form.startPoint = null;
        this.form.endPoint = null;
        this.form.rankPoint = null;
        this.submitForm();
      },
      getDataFromApi() {
        this.form.errors = [];
        this.datatable.loading = true;
        return new Promise((resolve) => {
          ApiService.setHeader();
          ApiService.post("/Api/Report/RankBellPoint/Search", {
            GroupBy: this.datatable.options.groupBy,
            GroupDesc: this.datatable.options.groupDesc,
            ItemsPerPage: this.datatable.options.itemsPerPage,
            Page: this.datatable.options.page,
            SortBy: this.datatable.options.sortBy,
            SortDesc: this.datatable.options.sortDesc,
            SearchKeyword: this.form.searchKeyword,
            LevelMember: this.form.memberClassCode
              ? this.form.memberClassCode.value
              : null,
            StartMemberCard: this.form.startMemberCard
              ? parseInt(this.form.startMemberCard.value)
              : null,
            EndMemberCard: this.form.endMemberCard
              ? parseInt(this.form.endMemberCard.value)
              : null,
            TypePoint: this.form.typePoint
              ? this.form.typePoint.value
              : null,
            StartPoint: parseInt(this.form.startPoint),
            EndPoint: parseInt(this.form.endPoint),
            RankPoint: parseInt(this.form.rankPoint),
          })
            .then(({ data }) => {
              if(data.total > 0){
                data.items.forEach((value) => {
                  value.id = value.id + (Math.random() + 1).toString(36).substring(7); // Warning Dup key error
                });
              }
              resolve({
                items: data.items,
                total: data.total,
              });
            })
            .finally(() => {
              this.datatable.loading = false;
            });
        });
      },
      getStartMemberCardFromApi() {
        return new Promise((resolve) => {
          ApiService.setHeader();
          ApiService.post("/Api/Report/MemberCardOptions/Search", {
            Search: this.form.startMemberCardSearch,
            Page : 1,
            ItemsPerPage : 30,
          })
            .then(({ data }) => {
              resolve(data);
            })
            .finally(() => {
              this.form.startMemberCardLoading = false;
            });
        });
      },
      getEndMemberCardFromApi() {
        return new Promise((resolve) => {
          ApiService.setHeader();
          ApiService.post("/Api/Report/MemberCardOptions/Search", {
            Search : this.form.endMemberCardSearch,
            Page : 1,
            ItemsPerPage : 30,
            StartMemberCard : (this.form.startMemberCard != null)
              ? parseInt(this.form.startMemberCard.value)
              : null,
          })
            .then(({ data }) => {
              resolve(data);
            })
            .finally(() => {
              this.form.endMemberCardLoading = false;
            });
        });
      },
      ChangeStartMemberCard() {
        this.getEndMemberCardFromApi().then((data) => {
          this.form.endMemberCardItems = data;
        });
      },
      Export() {
        var SortBy = "";
        var SortDesc = "";

        this.datatable.options.sortBy.forEach((val) => {
          SortBy += '&SortBy='+val;
        });
        this.datatable.options.sortDesc.forEach((val) => {
          SortDesc += '&SortDesc='+val;
        });

        var TypePoint = this.form.typePoint
          ? this.form.typePoint.value
          : "";
        var LevelMember = this.form.memberClassCode
          ? this.form.memberClassCode.value
          : "";
        var StartMemberCard = this.form.startMemberCard
          ? parseInt(this.form.startMemberCard.value)
          : "";
        var EndMemberCard = this.form.endMemberCard
          ? parseInt(this.form.endMemberCard.value)
          : "";

        var StartPoint = parseInt(this.form.startPoint);
        var EndPoint = parseInt(this.form.endPoint);
        var RankPoint = parseInt(this.form.rankPoint);
        
        if (isNaN(StartPoint)) StartPoint = ""
        console.log(StartPoint)
        if (isNaN(EndPoint)) EndPoint = ""
        if (isNaN(RankPoint)) RankPoint = ""
        // return
        window.open(
          "/Files/Export/RankBellPoint?searchKeyword=" +
            this.form.searchKeyword +
            "&TypePoint=" + 
            TypePoint +
            "&StartPoint=" + 
            StartPoint +
            "&EndPoint=" + 
            EndPoint +
            "&RankPoint=" + 
            RankPoint +
            "&LevelMember=" + 
            LevelMember +
            "&StartMemberCard=" + 
            StartMemberCard +
            "&EndMemberCard=" + 
            EndMemberCard +
            SortBy + SortDesc +
            "&token=" +
            this.token,
          "_blank"
        );
      },
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: this.$t("MENU.REPORT.SECTION"), route: "/Report" },
        { title: this.$t("MENU.REPORT.RANK_BELL_POINT") },
      ]);
    },
    computed: {
      title() {
        return this.$t("MENU.REPORT.RANK_BELL_POINT_SEARCH");
      },
      token() {
        return JwtService.getToken();
      },
    },
    watch: {
      "datatable.options": {
        handler() {
          this.getDataFromApi().then((data) => {
            this.datatable.total = data.total;
            this.datatable.items = data.items;
          });
        },
        deep: true,
      },
      "form.startMemberCardSearch": {
        handler() {
          this.getStartMemberCardFromApi().then((data) => {
            this.form.startMemberCardItems = data;
          });
        },
      },
      "form.endMemberCardSearch": {
        handler() {
          this.getEndMemberCardFromApi().then((data) => {
            this.form.endMemberCardItems = data;
          });
        },
      },
    },
  };
  </script>
  <style lang="scss" scoped></style>